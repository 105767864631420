<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="'新建跟进'" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <div class="line"></div>
        <van-form @submit="onSubmit">
          <van-field
              readonly
              clickable
              name="picker"
              v-model="formInline.followTypeName"
              label="跟进方式"
              placeholder="请选择"
              @click="showTypePicker = true"
              :required="true"
              :rules="[{ required: true, message: '请选择跟进方式' }]"
          />
          <van-popup v-model="showTypePicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="followTypeOptions"
                value-key="dictLabel"
                @confirm="onConfirmType"
                @cancel="showTypePicker = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              name="calendar"
              :value="formInline.followDate"
              label="选择时间"
              placeholder="点击选择日期"
              @click="showDataTimePop = true"
          />

          <textAreaInput :remark="formInline.followContent" @change="handleContentChange"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="isShowAuth">
            <template #input>
              <van-uploader :after-read="afterRead">
                <div class="flex">
                  <i class="iconfont icon-fujian1 mrg-r-10" style="font-size: 20px"></i>
                  <span>点击上传</span>
                </div>
              </van-uploader>
            </template>
          </van-field>
          <van-button class="btn" block type="info" native-type="submit">提交</van-button>
        </van-form>
      </div>
    </div>

    <van-popup v-model="showDataTimePop" style="width: 70%">
      <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="请选择跟进时间"
          @confirm="onConfirmTime"
      />
    </van-popup>
  </div>
</template>
<script>
import {changeToCurrentDay} from '@/utils/ruoyi';
import {addFollowCost} from '@/api/crm/bussiness';
import {submiteUpload} from '@/api/system/config';

export default {
  data() {
    return {
      currentDate: new Date(),
      formInline: {
        followTypeName: '',
        followType: null,
        followDate: null,
        followContent: null,
        costId: this.$route.query.id,
      },
      showPicker: false,
      showCalendar: false,
      showTypePicker: false,
      uploader: [],
      followTypeOptions: [],

      showDataTimePop: false,
      file: null,
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    // 跟进类型
    this.getDicts('follow_type').then((response) => {
      this.followTypeOptions = response.data;
    });
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/crm/cost');
      this.$router.go(-1)
    },

    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },

    onConfirmType(value) {
      this.formInline.followTypeName = value.dictLabel;
      this.formInline.followType = value.dictValue;
      this.showTypePicker = false;
    },

    onConfirmTime(value) {
      const time = changeToCurrentDay(value);
      this.formInline.followDate = time;
      this.showDataTimePop = false;
    },

    async onSubmit() {
      const result = await addFollowCost(this.formInline);
      if (result.code == 200) {
        const params = new FormData();
        params.append('businessType', 'cost_follow');
        params.append('businessId', result.data);
        params.append('file', this.file);
        const res = await submiteUpload(params);
        if (res.code == 200) {
          this.$toast.success('添加成功');
          this.handleGoBack();
        }
      }
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.file = file.file;
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    handleContentChange(val) {
      this.formInline.followContent = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end

::v-deep .van-radio__label {
  line-height: 100px;
}

::v-deep .van-radio__icon .van-icon {
  width: none;
  height: none;
  line-height: none;
}

::v-deep .van-radio__icon {
  line-height: 0;
}
</style>
